import React from "react";
import * as Sentry from "@sentry/browser";

import { ErrorBoundaryProps, ErrorBoundaryState, SENTRY_ERROR_KEY, SENTRY_ERROR_TYPE } from "Types";
import { frontyEnv } from "Constants";

const defaultErrorState: ErrorBoundaryState = { hasError: false, error: null, componentStack: null };

class SentryErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = defaultErrorState;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const { metadata } = this.props;
        this.setState({ hasError: true, error: error, componentStack: errorInfo.componentStack });
        Sentry.withScope(scope => {
            !!metadata && scope.setExtra(SENTRY_ERROR_KEY.METADATA, JSON.stringify(metadata));
            scope.setExtra(SENTRY_ERROR_KEY.TYPE, SENTRY_ERROR_TYPE.GENERAL);
            Sentry.captureException(error);
        });
    }

    resetErrorBoundary() {
        this.setState(defaultErrorState);
    }

    reloadCurrentPage = () => {
        this.resetErrorBoundary();
        window.location.reload();
    };

    render(): React.ReactNode {
        const { hasError, error, componentStack } = this.state;
        const { children } = this.props;
        const environmentIsDevelopment = process.env.NODE_ENV !== frontyEnv.PROD;
        if (hasError) {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh"
                    }}
                >
                    <div style={{ width: "50%", paddingBottom: "1rem" }}>
                        <h1>Något har gått snett...</h1>
                        <p>Var god och återkom lite senare eller kontakta oss på support@qopla.com</p>
                    </div>
                    {environmentIsDevelopment && (
                        <div style={{ width: "50%", paddingBottom: "1rem" }}>
                            <h3>Detaljer</h3>
                            {hasError && error?.toString()}
                            <br />
                            {componentStack}
                        </div>
                    )}
                    <button
                        style={{ width: "150px", height: "50px", fontSize: "1.2rem" }}
                        onClick={this.reloadCurrentPage}
                    >
                        Ladda om
                    </button>
                </div>
            );
        }

        return children;
    }
}

export default SentryErrorBoundary;
