import React from "react";
import { Navigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { ErrorBoundaryProps, ErrorBoundaryState, SENTRY_ERROR_KEY, SENTRY_ERROR_TYPE } from "Types";
import { isAtLeastAdmin } from "Utils";
import { ErrorBoundary } from "Atoms";

const defaultErrorState: ErrorBoundaryState = { hasError: false, error: null, componentStack: null };

class GeneralRedirectErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = defaultErrorState;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const { metadata } = this.props;
        this.setState({ hasError: true, error: error, componentStack: errorInfo.componentStack });
        Sentry.withScope(scope => {
            !!metadata && scope.setExtra(SENTRY_ERROR_KEY.METADATA, JSON.stringify(metadata));
            scope.setExtra(SENTRY_ERROR_KEY.TYPE, SENTRY_ERROR_TYPE.GENERAL);
            Sentry.captureException(error);
        });
    }

    resetErrorBoundary() {
        this.setState(defaultErrorState);
    }

    handleNavigateClick = () => {
        const { selectedValues } = this.props;
        if (!!selectedValues) {
            const user = selectedValues?.authenticatedUser;
            const redirectTo = !!isAtLeastAdmin(user?.roles ?? []) ? "/admin/companies" : "admin/startscreen";
            return <Navigate to={redirectTo} />;
        }
    };

    reloadCurrentPage = () => {
        this.resetErrorBoundary();
        window.location.reload();
    };

    render(): React.ReactNode {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <ErrorBoundary
                    type={SENTRY_ERROR_TYPE.GENERAL}
                    errorBoundaryState={{ errorState: this.state, reloadCurrentPage: this.reloadCurrentPage }}
                />
            );
        }

        return children;
    }
}

export default GeneralRedirectErrorBoundary;
