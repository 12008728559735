import React, { useEffect, useState } from "react";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaMinus } from "@react-icons/all-files/fa/FaMinus";

import { modalNames } from "Constants";
import { printCashChange } from "TempUtils";
import { IModalContext } from "Providers";
import { SelectedPos } from "Types";
import { roundToTwo } from "NumberUtils";
import {
    Modal,
    ModalHeader,
    ModalActions,
    ModalBody,
    ModalCloseBtn,
    RadioButtonGroup,
    InputGroup,
    InputRightAddon
} from "Molecules";
import {
    Box,
    Text,
    Flex,
    CustomRadioButton,
    Keypad,
    KeypadInput,
    KeypadButtons,
    KeypadButton,
    Divider,
    Button
} from "Atoms";

const keypadButtons = [
    { value: "1", children: 1 },
    { value: "2", children: 2 },
    { value: "3", children: 3 },
    { value: "4", children: 4 },
    { value: "5", children: 5 },
    { value: "6", children: 6 },
    { value: "7", children: 7 },
    { value: "8", children: 8 },
    { value: "9", children: 9 },
    { value: ".", children: "." },
    { value: 0, children: 0 },
    { value: "DEL", children: "DEL" }
];

type Props = {
    persistedChange: number;
    shopId: string;
    selectedPos: SelectedPos;
    handlePosCashChange: (changeAmount: number) => void;
    openDrawer: () => void;
};

const modalCashChangeMode = {
    ADD_CHANGE: "addCashChange",
    REMOVE_CHANGE: "removeCashChange"
};

const IngoingCashChangeModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const [numpadState, setNumpadState] = useState({
        persistedChange: 0.0,
        currentChange: 0.0,
        numPadOutput: "",
        valueToSave: 0.0
    });

    const [cashChangeMode, setCashChangeMode] = useState<string>(modalCashChangeMode.ADD_CHANGE);

    const { persistedChange, selectedPos, handlePosCashChange, openDrawer } = modalContent;

    useEffect(() => {
        setNumpadState(prevState => ({
            ...prevState,
            persistedChange: persistedChange,
            currentChange: persistedChange
        }));
    }, []);

    const handleClick = (key: string) => {
        if (String(key).includes("DEL")) {
            setNumpadState(prevState => {
                let numPadOutput = numpadState.numPadOutput.substring(0, numpadState.numPadOutput.length - 1);
                const prevPersistedChange =
                    typeof prevState.persistedChange === "undefined" ? 0 : prevState.persistedChange;
                return {
                    ...prevState,
                    numPadOutput: numPadOutput,
                    valueToSave: +numPadOutput,
                    currentChange:
                        numPadOutput === "" ? prevPersistedChange : prevPersistedChange + parseFloat(numPadOutput)
                };
            });
        } else {
            setNumpadState((prevState: any) => {
                const prevNumpadOutput = prevState.numPadOutput.toString();
                const prevPersistedChange =
                    typeof prevState.persistedChange === "undefined" ? 0 : prevState.persistedChange;
                let numPadOutput =
                    key === "." && (prevNumpadOutput === "" || prevNumpadOutput.indexOf(key) > -1)
                        ? prevNumpadOutput
                        : key.toString();
                if (cashChangeMode === modalCashChangeMode.REMOVE_CHANGE) {
                    return {
                        ...prevState,
                        numPadOutput: numPadOutput,
                        currentChange:
                            numPadOutput === "" ? prevPersistedChange : prevPersistedChange - parseFloat(numPadOutput),
                        valueToSave: -numPadOutput
                    };
                } else {
                    return {
                        ...prevState,
                        numPadOutput,
                        currentChange:
                            numPadOutput === "" ? prevPersistedChange : prevPersistedChange + parseFloat(numPadOutput),
                        valueToSave: numPadOutput
                    };
                }
            });
        }
    };

    const toggleCashChangeMode = (radioButtonValue: string) => {
        let updatedCurrentChange =
            radioButtonValue === modalCashChangeMode.ADD_CHANGE
                ? numpadState.persistedChange + Math.abs(numpadState.valueToSave)
                : numpadState.persistedChange - Math.abs(numpadState.valueToSave);

        setNumpadState(prevState => ({
            ...prevState,
            currentChange: updatedCurrentChange
        }));
        setCashChangeMode(radioButtonValue);
    };

    const handleClose = () => {
        setNumpadState(prevState => ({
            ...prevState,
            numPadOutput: "",
            currentChange: persistedChange,
            valueToSave: 0.0
        }));
        closeModal(modalNames.INGOINGCASHCHANGEMODAL);
    };

    let hasPersistedChange = !!numpadState.persistedChange;

    return (
        <Modal
            size="xs"
            open={true}
            closeOnDimmerClick
            data-test="cash-change-modal"
            onClose={() => {
                handleClose();
                numpadState.persistedChange >= 0.0 && closeModal(modalNames.INGOINGCASHCHANGEMODAL);
            }}
        >
            <ModalHeader className="space-between">
                <Flex alignItems={"baseline"}>
                    <Text color="gray.500" pr={1} fontWeight={300} mb={0}>
                        Växel för "{selectedPos.name}":
                    </Text>
                    <Text fontSize={"1.5rem"}>
                        {numpadState.currentChange > 0 ? roundToTwo(numpadState.currentChange) : 0} kr
                    </Text>
                </Flex>
                {hasPersistedChange && (
                    <ModalCloseBtn
                        onClick={() => {
                            closeModal(modalNames.INGOINGCASHCHANGEMODAL);
                        }}
                    />
                )}
            </ModalHeader>
            <Divider />
            <ModalBody>
                <Box>
                    <Keypad
                        isDefaultOpen
                        closeOnOutsideClick={false}
                        onChange={(buttonValue: any) => handleClick(buttonValue)}
                        value={numpadState.numPadOutput}
                    >
                        <InputGroup w="100%" size="xl" fontSize={"1.5rem"}>
                            <KeypadInput
                                fullWidth
                                placeholder="Summa"
                                borderTopRightRadius={0}
                                borderBottomRightRadius={0}
                            />
                            <InputRightAddon children={<Box>kr</Box>} />
                        </InputGroup>
                        {selectedPos.cashChangeInputs.length > 0 && (
                            <RadioButtonGroup
                                name="timeSpan"
                                value={cashChangeMode}
                                activeThemeColor="green"
                                width="100%"
                                onValueChanged={(radioButtonValue: any) => {
                                    toggleCashChangeMode(radioButtonValue);
                                }}
                            >
                                <CustomRadioButton
                                    value={modalCashChangeMode.ADD_CHANGE}
                                    borderTopLeftRadius={4}
                                    borderBottomLeftRadius={4}
                                    width="50%"
                                    fontSize={"1.3rem"}
                                    py={8}
                                    rightIcon={FaPlus}
                                >
                                    <Text mb={0} width="100%">
                                        Växel in
                                    </Text>
                                </CustomRadioButton>
                                <CustomRadioButton
                                    value={modalCashChangeMode.REMOVE_CHANGE}
                                    borderTopRightRadius={4}
                                    borderBottomRightRadius={4}
                                    width="50%"
                                    fontSize={"1.3rem"}
                                    py={8}
                                    rightIcon={FaMinus}
                                >
                                    <Text mb={0} width="100%">
                                        Växel ut
                                    </Text>
                                </CustomRadioButton>
                            </RadioButtonGroup>
                        )}
                        <KeypadButtons borderTop="1px solid #F7FAFC">
                            {keypadButtons.map(button => (
                                <KeypadButton
                                    key={button.value}
                                    {...button}
                                    data-test={`keypad-button-${button.value}`}
                                />
                            ))}
                        </KeypadButtons>
                    </Keypad>
                </Box>
            </ModalBody>
            <ModalActions>
                <Button
                    fullWidth
                    themeColor="green"
                    disabled={numpadState.numPadOutput === "" || numpadState.currentChange < 0}
                    onClick={() => {
                        handlePosCashChange(parseFloat(`${numpadState.valueToSave}`));
                        openDrawer();
                        printCashChange(
                            selectedPos.receiptPrinter,
                            parseFloat(`${numpadState.valueToSave}`),
                            numpadState.currentChange
                        );
                        handleClose();
                    }}
                    fontSize={"1.3rem"}
                    py={6}
                    data-test="keypad-button-save"
                >
                    Spara
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default IngoingCashChangeModal;
