import React from "react";
import * as Sentry from "@sentry/browser";

import { ErrorBoundaryProps, ErrorBoundaryState, SENTRY_ERROR_KEY, SENTRY_ERROR_TYPE } from "Types";
import { ErrorBoundary } from "Atoms";
import NotFound from "./admin/components/NotFound";

type ReloadErrorState = Pick<ErrorBoundaryState, "hasError" | "error" | "type">;

const defaultErrorState: ReloadErrorState = { hasError: false, error: null, type: null };

class ReloadErrorBoundary extends React.Component<ErrorBoundaryProps, ReloadErrorState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = defaultErrorState;
    }
    componentDidCatch(error: Error, _: React.ErrorInfo) {
        const { metadata } = this.props;
        this.setState({ hasError: true, error: error });
        Sentry.withScope(scope => {
            !!metadata && scope.setExtra(SENTRY_ERROR_KEY.METADATA, JSON.stringify(metadata));
            !!this.props.type && scope.setExtra(SENTRY_ERROR_KEY.COMPONENT, this.props.type);
            scope.setExtra(SENTRY_ERROR_KEY.TYPE, SENTRY_ERROR_TYPE.RELOAD);
            Sentry.captureException(error);
        });

        setTimeout(() => {
            this.reloadCurrentPage();
        }, 30000);
    }

    resetErrorBoundary() {
        this.setState(defaultErrorState);
    }

    reloadCurrentPage = () => {
        this.resetErrorBoundary();
        window.location.reload();
    };

    render(): React.ReactNode {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <ErrorBoundary
                    type={SENTRY_ERROR_TYPE.RELOAD}
                    errorBoundaryState={{ errorState: this.state, reloadCurrentPage: this.reloadCurrentPage }}
                />
            );
        }

        const Component = this.props.sentryChild;

        if (Component) return <Component {...this.props} />;

        return <NotFound />;
    }
}

export default ReloadErrorBoundary;
