import React from "react";
import { Navigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { ErrorBoundaryProps, ErrorBoundaryState, SENTRY_ERROR_KEY, SENTRY_ERROR_TYPE } from "Types";
import { envConstants } from "Constants";
import { ErrorBoundary } from "Atoms";
import NotFound from "./admin/components/NotFound";

type PublicErrorBoundaryState = Pick<ErrorBoundaryState, "hasError" | "error" | "type">;

const defaultErrorState: PublicErrorBoundaryState = { hasError: false, error: null, type: null };

class PublicErrorBoundary extends React.Component<ErrorBoundaryProps, PublicErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = defaultErrorState;
    }

    componentDidCatch(error: Error, _: React.ErrorInfo) {
        this.setState({ hasError: true, error: error, type: this.props?.type ?? "" });
        Sentry.withScope(scope => {
            !!this.props.type && scope.setExtra(SENTRY_ERROR_KEY.COMPONENT, this.props.type);
            scope.setExtra(SENTRY_ERROR_KEY.TYPE, SENTRY_ERROR_TYPE.PUBLIC);
            scope.setExtra(SENTRY_ERROR_KEY.FRONT_END_VERSION, envConstants.VERSION);
            Sentry.captureException(error);
        });
    }

    resetErrorBoundary() {
        this.setState(defaultErrorState);
    }

    reloadCurrentPage = () => {
        this.resetErrorBoundary();
        window.location.reload();
    };

    render(): React.ReactNode {
        const { hasError } = this.state;

        if (hasError) {
            return (
                <ErrorBoundary
                    type={SENTRY_ERROR_TYPE.PUBLIC}
                    errorBoundaryState={{ errorState: this.state, reloadCurrentPage: this.reloadCurrentPage }}
                />
            );
        }

        const Component = this.props.sentryChild;

        if (Component) return <Component {...this.props} />;

        return <NotFound />;
    }
}

export default PublicErrorBoundary;
