import React from "react";
import * as Sentry from "@sentry/browser";

import { ErrorBoundaryProps, ErrorBoundaryState, SENTRY_ERROR_KEY, SENTRY_ERROR_TYPE } from "Types";
import { ErrorBoundary } from "Atoms";
import NotFound from "./admin/components/NotFound";

type RedirectErrorBoundaryState = ErrorBoundaryState;

const defaultErrorState: RedirectErrorBoundaryState = {
    hasError: false,
    error: null,
    componentStack: null
};

class RedirectErrorBoundary extends React.Component<ErrorBoundaryProps, RedirectErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = defaultErrorState;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const { metadata, type } = this.props;
        this.setState({ hasError: true, error: error, componentStack: errorInfo?.componentStack });
        Sentry.withScope(scope => {
            !!metadata && scope.setExtra(SENTRY_ERROR_KEY.METADATA, JSON.stringify(metadata));
            scope.setExtra(SENTRY_ERROR_KEY.TYPE, SENTRY_ERROR_TYPE.REDIRECT);
            !!type && scope.setExtra(SENTRY_ERROR_KEY.TYPE, type);
            Object.keys(errorInfo).forEach((key: string) => {
                const convertedKey = key as keyof typeof errorInfo;
                scope.setExtra(convertedKey, errorInfo[convertedKey]);
            });
            Sentry.captureException(error);
        });
    }

    resetErrorBoundary() {
        this.setState(defaultErrorState);
    }

    reloadCurrentPage = () => {
        this.resetErrorBoundary();
        window.location.reload();
    };

    render(): React.ReactNode {
        const { hasError } = this.state;
        if (hasError) {
            return (
                <ErrorBoundary
                    type={SENTRY_ERROR_TYPE.REDIRECT}
                    errorBoundaryState={{ errorState: this.state, reloadCurrentPage: this.reloadCurrentPage }}
                />
            );
        }
        const Component = this.props.sentryChild;

        if (Component) return <Component {...this.props} />;

        return <NotFound />;
    }
}

export default RedirectErrorBoundary;
